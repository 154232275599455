<template>
  <div class="home">
    <div class="main" v-if="type == 2 || type == 5">
      <div class="main_tab space_around">
        <div v-for="(item, index) in typeList" :key="item.type" :class="['btn center', { active: type == item.type }]" @click.stop="handleChange(index, item.type)">
          <div class="ic">{{ item.name }}</div>
        </div>
        <div class="slider" ref="slider" :style="{ left: left + 'px' }"></div>
      </div>
      <div class="main_content" v-html="contents"></div>
    </div>
    <div class="main" v-else-if="type == 3">
      <div class="main_iii">
        <div class="space_around mian_iii_top">
          <div v-for="(item, index) in data.about.qr" :key="index">
            <div class="mian_iii_top_qr">
              <img src="../../../assets/image/QQ.jpg" alt width="100%" />
            </div>
            <div style="margin-top: 11px; text-align: center">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="main" v-else>
      <div class="main_ii_center">
        <div v-html="contents"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getagreement } from '@/api/index';
export default {
  data() {
    return {
      contents: '',
      isselect: false,
      helptype: [
        {
          type: '取回教程',
          value: 1,
        },
      ],
      typeList: [
        { name: '隐私协议', type: 2 },
        { name: '用户协议', type: 5 },
      ],
      titleqqq: '网站充值教程',
      dragging: false,
      startX: 0,
      left: 4,
      title: '',
      type: 2,
      data: {
        about: {
          qr: [
            // {
            //   url: "https://img2.baidu.com/it/u=3169331048,4117927422&fm=253&fmt=auto&app=138&f=GIF?w=200&h=200",
            //   name: "QQ客服",
            // },
            // {
            //   url: "https://img1.baidu.com/it/u=2426361314,145551708&fm=253&fmt=auto&app=138&f=GIF?w=200&h=200",
            //   name: "QQ2群",
            // },
            {
              url: '../../../assets/image/QQ.jpg',
              name: 'QQ1群',
            },
          ],
        },
      },
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route: {
      handler() {
        this.initData();
      },
    },
  },
  methods: {
    initData() {
      this.type = +this.$route.query?.type || 2;
      const mapOpt = { 2: '隐私协议', 3: '关于我们', 4: '帮助中心', 5: '用户协议', 7: '反洗钱协议' };
      this.title = mapOpt[this.type];
      this.getList(this.type);
      if (this.type == 5) {
        let timer = setTimeout(() => {
          this.setLeft(1);
          clearTimeout(timer);
        }, 200);
      }
    },
    playAudio() {
      this.$store.commit('playAudio');
    },
    handleChange(index, type) {
      this.playAudio();
      this.type = type;
      this.setLeft(index);
      this.getList(type);
      this.$router.replace(`/help?type=${type}`);
    },
    setLeft(index) {
      if (!this.$refs.slider) return;
      const width = this.$refs.slider.offsetWidth;
      this.left = index ? width * index : 4;
    },
    getList(type) {
      getagreement(type).then(res => {
        if (res?.data?.code != 200) return;
        if (!res?.data?.data?.content) return;
        this.contents = res?.data?.data.content;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  margin: 0 @pcSpace;
  @media @max750 {
    margin: 0 @mSpace;
    text-align: left !important;
  }
}
.main {
  margin-top: 20px;
  width: 100%;
}
.main_tab {
  margin: 0 auto;
  width: 203px;
  height: 42px;
  background: #323436;
  border-radius: 70px;
  opacity: 1;
  font-size: 14px;
  color: #727477;
  position: relative;
}
.btn {
  width: 49%;
  height: 35px;
  opacity: 1;
  .ic {
    position: relative;
    z-index: 2;
  }
}
.active {
  color: @main;
  font-weight: bold;
}
.slider {
  width: 49%;
  height: 35px;
  position: absolute;
  transition: all 0.2s;
  // background: linear-gradient(-90deg, #3a97e4 0%, #25f484 100%);
  box-sizing: border-box;
  border: 1px solid @main;
  border-radius: 70px;
  z-index: 1;
}
.slider::before {
  content: ''; /*必须设置content属性*/
  width: 100%;
  display: block;
  height: 100%;
  background: transparent;
  z-index: 3;
}
.main_content {
  width: 100%;
  font-size: 18px;
  padding: 20px 0;
  box-sizing: border-box;
  @media @max750 {
    font-size: 16px;
  }
}
.main_ii_top {
  width: 100%;
  margin: 0 auto;
}
.main_ii_center {
  margin-top: 20px;
  position: relative;
}

.main_iii {
  width: 100%;
  height: 350px;
  background: #101115;
  border-radius: 14px;
  opacity: 1;
  padding: 69px 42px;
  box-sizing: border-box;
}
.mian_iii_top {
  width: 100%;
}
.mian_iii_top_qr {
  width: 200px;
  height: 200px;
  img{
    width: 100%;
    height: 100%;
  }
}
.mian_iii_center {
  margin-top: 35px;
}
.main_ii_top_left {
  width: 224px;
  height: 42px;
  background: #323436;
  border-radius: 70px;
  position: relative;
  z-index: 5;
}
.main_ii_top_left_xl {
  position: absolute;
  width: 100%;
  left: 0;
  top: 45px;

  background: #101115;
}
.main_ii_top_left_xl_btn {
  width: 100%;
  height: 42px;
  opacity: 1;

  &:hover {
    .btn-bg;
  }
}
</style>
